<template>
  <div>
    <SpinnerLoader v-if="programsStatus !== 'success'" :loading="programsStatus" />
    <template v-else-if="programsStatus === 'success' && programs">
      <div class="table-responsive mb-0 programs">
        <table class="table table-sm small table-hover table-bordered ws-nowrap sticky-headers sticky-footer">
          <thead>
            <tr>
              <th rowspan="3">
                <div class="resizable program_release">
                  <button class="btn-link btn-transparent mr-1" :title="$t('reports.expandOrCollapseRows')" @click="toggleAllRowsVisibility()">
                    <b-icon :icon="expandAllRows ? 'chevron-bar-contract' : 'chevron-expand'"></b-icon>
                  </button>
                  {{ $t('reports.programName') }}
                </div>
              </th>
              <th rowspan="3" style="min-width: 78px">{{ $t('reports.dates') }}</th>
              <th rowspan="3" style="white-space: break-spaces; min-width: 69px">{{ $t('reports.programTimeStart') }}</th>
              <th rowspan="3" style="white-space: break-spaces">{{ $t('reports.programTiming') }}</th>
              <th rowspan="3" style="white-space: break-spaces">{{ $t('reports.blockPosition') }}</th>
              <th colspan="2">{{ $t('table.seconds') }}</th>
              <th colspan="6">wGRP</th>
              <th colspan="3">{{ $t('table.budget') }}</th>
              <th rowspan="3" style="white-space: break-spaces">{{ $t('reports.costPerMinute') }}, wGRP</th>
            </tr>
            <tr>
              <th>{{ $t('table.open') }}</th>
              <th>{{ $t('reports.soldOut') }}</th>

              <th colspan="3">{{ $t('table.open') }}</th>
              <th colspan="3">{{ $t('reports.soldOut') }}</th>

              <th colspan="3">{{ $t('reports.soldOut') }}</th>
            </tr>
            <tr>
              <th class="text-right">{{ $t('table.fact') }}</th>
              <th class="text-right">{{ $t('table.fact') }}</th>

              <th class="text-right">{{ $t('table.plan') }}</th>
              <th class="text-right">{{ $t('table.fact') }}</th>
              <th class="text-right">{{ $t('table.fact') }}/{{ $t('table.plan') }}</th>
              <th class="text-right">{{ $t('table.plan') }}</th>
              <th class="text-right">{{ $t('table.fact') }}</th>
              <th class="text-right">{{ $t('table.fact') }}/{{ $t('table.plan') }}</th>

              <th class="text-right">{{ $t('table.plan') }}</th>
              <th class="text-right">{{ $t('table.fact') }}</th>
              <th class="text-right">{{ $t('table.fact') }}/{{ $t('table.plan') }}</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(group, gpIndex) in programs.items">
              <tr :key="gpIndex + '_0'" class="bg-light">
                <th colspan="5" class="cursor-pointer" @click="toggleGroupRowsVisibility(group)">
                  <b-icon :icon="group.visible ? 'chevron-up' : 'chevron-down'" :title="$t('reports.expandOrCollapseRow')"></b-icon>
                  {{ group.label }}
                </th>
                <th class="text-right">{{ group.open_fact_seconds | toFixedAndSpace(0) }}</th>
                <th class="text-right">{{ group.sold_fact_seconds | toFixedAndSpace(0) }}</th>

                <th class="text-right">{{ group.open_plan_wgrp | toFixedAndSpace }}</th>
                <th class="text-right">{{ group.open_fact_wgrp | toFixedAndSpace }}</th>
                <th class="text-right">{{ group.open_plan_fact_wgrp | toFixedAndSpace }}</th>
                <th class="text-right">{{ group.sold_plan_wgrp | toFixedAndSpace }}</th>
                <th class="text-right">{{ group.sold_fact_wgrp | toFixedAndSpace }}</th>
                <th class="text-right">{{ group.sold_plan_fact_wgrp | toFixedAndSpace }}</th>

                <th class="text-right">{{ group.sold_plan_budget | toFixedAndSpace }}</th>
                <th class="text-right">{{ group.sold_fact_budget | toFixedAndSpace }}</th>
                <th class="text-right">{{ group.sold_plan_fact_budget | toFixedAndSpace }}</th>

                <th class="text-right">{{ group.sold_avg_cpp | toFixedAndSpace }}</th>
              </tr>
              <template v-if="group.visible">
                <tr v-for="(row, rIndex) in group.programs" :key="gpIndex + '_1_' + rIndex">
                  <td name="program_release">{{ row.program_name }}</td>
                  <td>{{ row.date | convertDate }}</td>
                  <td>{{ row.start_time_at }}</td>
                  <td>{{ row.duration }}</td>
                  <td>{{ row.position === 'inner' ? $t('reports.inner') : row.position === 'after' ? $t('reports.after') : '' }}</td>
                  <td class="text-right">{{ row.open_fact_seconds | toFixedAndSpace(0) }}</td>
                  <td class="text-right">{{ row.sold_fact_seconds | toFixedAndSpace(0) }}</td>

                  <td class="text-right">{{ row.open_plan_wgrp | toFixedAndSpace }}</td>
                  <td class="text-right">{{ row.open_fact_wgrp | toFixedAndSpace }}</td>
                  <td class="text-right">{{ row.open_plan_fact_wgrp | toFixedAndSpace }}</td>
                  <td class="text-right">{{ row.sold_plan_wgrp | toFixedAndSpace }}</td>
                  <td class="text-right">{{ row.sold_fact_wgrp | toFixedAndSpace }}</td>
                  <td class="text-right">{{ row.sold_plan_fact_wgrp | toFixedAndSpace }}</td>

                  <td class="text-right">{{ row.sold_plan_budget | toFixedAndSpace }}</td>
                  <td class="text-right">{{ row.sold_fact_budget | toFixedAndSpace }}</td>
                  <td class="text-right">{{ row.sold_plan_fact_budget | toFixedAndSpace }}</td>
                  <td class="text-right">{{ row.sold_avg_cpp | toFixedAndSpace }}</td>
                </tr>
              </template>
            </template>
          </tbody>

          <tfoot v-if="programs.totals" class="font-weight-bold">
            <tr>
              <td colspan="5">{{ $t('booking.total') }}</td>
              <td class="text-right">{{ programs.totals.open_fact_seconds | toFixedAndSpace(0) }}</td>
              <td class="text-right">{{ programs.totals.sold_fact_seconds | toFixedAndSpace(0) }}</td>

              <td class="text-right">{{ programs.totals.open_plan_wgrp | toFixedAndSpace }}</td>
              <td class="text-right">{{ programs.totals.open_fact_wgrp | toFixedAndSpace }}</td>
              <td class="text-right">{{ programs.totals.open_plan_fact_wgrp | toFixedAndSpace }}</td>
              <td class="text-right">{{ programs.totals.sold_plan_wgrp | toFixedAndSpace }}</td>
              <td class="text-right">{{ programs.totals.sold_fact_wgrp | toFixedAndSpace }}</td>
              <td class="text-right">{{ programs.totals.sold_plan_fact_wgrp | toFixedAndSpace }}</td>

              <td class="text-right">{{ programs.totals.sold_plan_budget | toFixedAndSpace }}</td>
              <td class="text-right">{{ programs.totals.sold_fact_budget | toFixedAndSpace }}</td>
              <td class="text-right">{{ programs.totals.sold_plan_fact_budget | toFixedAndSpace }}</td>
              <td class="text-right">{{ programs.totals.sold_avg_cpp | toFixedAndSpace }}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SpinnerLoader from '@/components/SpinnerLoader';
import toFixedAndSpace from '@/filters/toFixedAndSpace';
import convertDate from '@/filters/convertDate';
import { BIconChevronExpand, BIconChevronBarContract } from 'bootstrap-vue';

export default {
  name: 'ProgramsTable',
  // eslint-disable-next-line vue/no-unused-components
  components: { SpinnerLoader, BIconChevronExpand, BIconChevronBarContract },
  filters: { toFixedAndSpace, convertDate },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      isLocale: 'isLocale',
      isThemeHeader: 'isTheme',
      programs: 'getReportPrograms',
      programsStatus: 'getReportProgramsStatus',
      expandAllRows: 'getExpandAllRows',
    }),
  },
  destroyed() {
    this.$store.commit('clearReportPrograms');
  },
  methods: {
    toggleGroupRowsVisibility(group) {
      group.visible = !group.visible;
    },
    toggleAllRowsVisibility() {
      this.$store.commit('toggleExpandAllTableRows');
      for (let group in this.programs.items) {
        this.programs.items[group].visible = this.expandAllRows;
      }
    },
  },
};
</script>

<style lang="sass">
.reports-page div.table-responsive.programs
  height: calc(100vh - 210px)

.reports-page .programs table.table
  div.resizable.program_release
    min-width: 200px

  td[name="program_release"]
    max-width: 200px

@media (min-width: 2600px)
  .reports-page div.table-responsive.programs
    height: calc(100vh - 154px)
</style>